import React from "react";
import _ from "lodash";
import styled from "styled-components/macro";

import { Layout } from "../components/index";
import { getPages, htmlToReact, safePrefix } from "../utils";
import Helmet from "react-helmet";
import FeaturesList from "../components/FeaturesList";
import { css } from 'styled-components/macro'
import { getFeaturesByCategory } from '../utils/getFeaturesByCategory'

export default class Team extends React.Component {
  render() {
    let displayPosts = _.orderBy(
      getPages(this.props.pageContext.pages, "/oferta").filter(
        (page) => page.name !== "index"
      ),
      "frontmatter.date",
      "desc"
    );
    if (!displayPosts) {
      return null;
    }


    const featuresByCategory = getFeaturesByCategory(displayPosts)

    return (
      <Layout {...this.props}>
        <Helmet>
          <title>
            {_.get(this.props, "pageContext.frontmatter.title") &&
              _.get(this.props, "pageContext.frontmatter.title") + " - "}
            {_.get(this.props, "pageContext.site.siteMetadata.title")}
          </title>
        </Helmet>
        <div className="outer">
          <div className="inner-medium">
            <article className="post page post-full">
              <header className="post-header">
                <h1 className="post-title">
                  {_.get(this.props, "pageContext.frontmatter.title")}
                </h1>
              </header>
              {_.get(this.props, "pageContext.frontmatter.img_path") && (
                <div className="post-thumbnail">
                  <img
                    src={safePrefix(
                      _.get(this.props, "pageContext.frontmatter.img_path")
                    )}
                    alt={_.get(this.props, "pageContext.frontmatter.title")}
                  />
                </div>
              )}
              {_.get(this.props, "pageContext.frontmatter.subtitle") && (
                <div className="post-subtitle">
                  {htmlToReact(
                    _.get(this.props, "pageContext.frontmatter.subtitle")
                  )}
                </div>
              )}
              <div className="post-content">
                {htmlToReact(_.get(this.props, "pageContext.html"))}
              </div>
            </article>
          </div>
          {Object.entries(featuresByCategory).map(([category, posts], index) => {
            return (
              <SpacedDiv className="inner" key={index}>
                <h2 className="post-subtitle" css={css`border-bottom: none; font-size: 2rem; font-weight: bold`}>{category}</h2>
                <FeaturesList posts={posts} />
              </SpacedDiv>
            );
          })}
        </div>
      </Layout>
    );
  }
}

const SpacedDiv = styled.div`
  margin-bottom: 6rem;
`;
