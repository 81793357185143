import React from "react";
import _ from "lodash";
import { Link, safePrefix } from "../utils";
import VisuallyHidden from '@reach/visually-hidden'
import { css } from 'styled-components/macro'

const FeaturesList = ({ posts }) => {
  return (
    <div className="post-feed">
      {_.map(posts, (post, post_idx) => (
        <article key={post_idx} className="post post-card">
          <div className="post-card-inside">
            {_.get(post, "frontmatter.thumb_img_path") && (
              <Link
                className="post-card-thumbnail"
                to={safePrefix(_.get(post, "url"))}
              >
                <img
                  className="thumbnail"
                  src={safePrefix(_.get(post, "frontmatter.thumb_img_path"))}
                  alt={_.get(post, "frontmatter.title")}
                  loading="lazy"
                />
              </Link>
            )}
            <div className="post-card-content">
              <header className="post-header">
                <h2 className="post-title">
                  <Link to={safePrefix(_.get(post, "url"))} rel="bookmark">
                    {_.get(post, "frontmatter.title")}
                  </Link>
                </h2>
              </header>

              {_.get(post, "frontmatter.price") ? (
                <div className="post-excerpt" css={css`font-weight: bold`}>
                  <VisuallyHidden>Cena: </VisuallyHidden>{_.get(post, "frontmatter.price")}
                </div>
              ) : null}
              {_.get(post, "frontmatter.duration") ? (
                <div className="post-excerpt">
                  <VisuallyHidden>Czas trwania: </VisuallyHidden>
                  {_.get(post, "frontmatter.duration")}
                </div>
              ) : null}
              <div className="post-excerpt" css={css`margin-top: 1em;`}>
                <p>{_.get(post, "frontmatter.excerpt")}</p>
              </div>
            </div>
          </div>
        </article>
      ))}
    </div>
  );
};

export default FeaturesList;
